/* 
https://medium.com/@gwhi94/creating-a-gameboard-with-css-grid-47da8ac25078 
*/

#instructions{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 20px;   
}

html{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.foot {
    padding: 20px;
}

main {
    padding: 10px;
}

.gameboard{ 
    display: grid;
    grid-template-columns: 10px 120px 120px 120px 120px 120px 10px;
    grid-template-rows: 10px 120px 120px 120px 120px 120px 10px;
    justify-content: center;
}

h1{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 20px;

}

.boxA {
    border: 2px solid black;
    border-color: black;
    box-shadow: 0 0 80px 80px #f77575 inset, 0 0 0 0 #000000;
    transition: all 150ms;
    padding: 20px;
}
.boxA:hover {
    box-shadow: 0 0 80px 0 #f77575 inset, 0 0 10px 4px #f77575;
}

.boxAInvalid {
    border: 2px solid black;
    border-color: black;
    box-shadow: 0 0 80px 80px #f77575 inset, 0 0 0 0 #000000;
    transition: all 150ms;
    padding: 20px;
}

.boxB {
    border: 2px solid black;
    border-color: black;
    box-shadow: 0 0 80px 80px #75b8f7 inset, 0 0 0 0 #000000;
    transition: all 150ms;
    padding: 20px;
}
.boxB:hover {
    box-shadow: 0 0 80px 0 #75b8f7 inset, 0 0 10px 4px #75b8f7;
}

.boxBInvalid {
    border: 2px solid black;
    border-color: black;
    box-shadow: 0 0 80px 80px #75b8f7 inset, 0 0 0 0 #000000;
    transition: all 150ms;
    padding: 20px;
}

.height {
    float: right;
    right: 0px;
    padding: 10px;
    overflow: auto;
}

.piece{
    float: left;
    left: 0px;
    padding: 5px;
    overflow: auto;
}

.highlightedMove {
    border: 2px solid black;
    border-color: black;
    box-shadow: 0 0 80px 80px #4BB543 inset, 0 0 0 0 #000000;
    transition: all 150ms;
    padding: 20px;
}
.highlightedMove:hover {
    box-shadow: 0 0 80px 0 #4BB543 inset, 0 0 10px 4px #4BB543;
}

.highlightedBuild {
    border: 2px solid black;
    border-color:black;
    box-shadow: 0 0 80px 80px cyan inset, 0 0 0 0 #000000;
    transition: all 150ms;
    padding: 20px;
}
.highlightedBuild:hover {
    box-shadow: 0 0 80px 0 cyan inset, 0 0 10px 4px cyan;
}

.fromCell {
    border: 2px solid black;
    border-color:black;
    box-shadow: 0 0 80px 80px blue inset, 0 0 0 0 #000000;
    transition: all 150ms;
    padding: 20px;
}

.recentMove {
    border: 2px solid black;
    border-color:black;
    box-shadow: 0 0 80px 80px purple inset, 0 0 0 0 #000000;
    transition: all 150ms;
    padding: 20px;
}

.recentBuild {
    border: 2px solid black;
    border-color:black;
    box-shadow: 0 0 80px 80px #b19cd9 inset, 0 0 0 0 #000000;
    transition: all 150ms;
    padding: 20px;
}

.button {
    height: 50px;
    width: max-content;
    padding: 10px;
    font-size: 16px;
    text-align: center;
    border-color: #3498db;
    color: #fff;
    box-shadow: 0 0 40px 40px #3498db inset, 0 0 0 0 #000000;
    transition: all 150ms;
    border-radius: 10px;
    outline:none;
  }
  .button:hover {
      box-shadow: 0 0 10px 0 #3498db inset, 0 0 10px 4px #3498db;
      color: gray;
  }

  .maxHeight {
    border: 2px solid black;
    border-color:black;
    box-shadow: 0 0 80px 80px black inset, 0 0 0 0 #000000;
    transition: all 1000ms;
    color: white;
    font-size: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .wall {
    box-shadow: 0 0 5px 5px black inset, 0 0 0 0 #000000;
  }

  #step {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 28px;
    color: black;
    padding-left: 20px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 0px;
    margin: 0px;
    text-align: center;
  }

  #error {
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-size: 20px;
      min-height: 26.5px;
      margin: 0px;
      color: red;
      padding-left: 20px;
      padding-right: 5px;
      padding-top: 0px;
      padding-bottom: 10px;
      text-align: center;
  }

  #buttons {
      text-align: center;
      margin: 0px;
      padding: 0px;
  }